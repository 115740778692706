import React, { useState } from "react"
import {
  MailIcon,
  PhoneIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline"
import { useQueryParam, StringParam } from "use-query-params"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Notification from "../components/notification"
export default function Contact() {
  const [showNotification, setShowNotification] = React.useState(false)
  const [success, setSuccess] = useQueryParam("success", StringParam)
  const [showSaveButton, setShowSaveButton] = useState(false)
  React.useEffect(() => {
    if (success) setShowNotification(true)
  }, [success])
  const closeNotification = value => {
    setShowNotification(value)
    setSuccess(undefined, "replaceIn")
  }
  // React.useEffect(() => {
  //   if (success) setSuccess(undefined)
  // }, [success])
  return (
    <Layout withPattern={false}>
      <Notification show={showNotification} setShow={closeNotification} />
      {/* Header */}
      <Seo title="Contactez votre partenaire anti-plagiat | Compilatio par GEERD" />

      <div className="bg-stone-50">
        <div className="py-24 lg:py-32">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-stone-900 sm:text-5xl lg:text-6xl">
              Formulaire de prise de contact
            </h1>
            {/* <p className="mt-6 text-xl text-stone-500 max-w-3xl">
              Vel nunc non ut montes, viverra tempor. Proin lectus nibh
              phasellus morbi non morbi. In elementum urna ut volutpat. Sagittis
              et vel et fermentum amet consequat.
            </p> */}
          </div>
        </div>
      </div>

      {/* Contact section */}
      <section className="relative bg-white" aria-labelledby="contact-heading">
        <div className="absolute w-full h-1/2 bg-stone-50" aria-hidden="true" />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <h2 id="contact-heading" className="sr-only">
              Prise de contact
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* Contact information */}
              <div className="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-primary-500 to-primary-600 sm:px-10 xl:p-12">
                {/* Decorative angle backgrounds */}
                <div
                  className="absolute inset-0 pointer-events-none sm:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width={343}
                    height={388}
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width={359}
                    height={339}
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width={160}
                    height={678}
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-white">
                  Contact information
                </h3>
                {/* <p className="mt-6 text-base text-primary-50 max-w-3xl">
                  Nullam risus blandit ac aliquam justo ipsum. Quam mauris
                  volutpat massa dictumst amet. Sapien tortor lacus arcu.
                </p> */}
                <dl className="mt-8 space-y-6">
                  <dt>
                    <span className="sr-only">Phone number</span>
                  </dt>
                  <dd className="flex text-base text-primary-50">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6 text-primary-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+212629229222</span>
                    <span className="ml-3">+212668843699</span>
                  </dd>
                  <dt>
                    <span className="sr-only">Email</span>
                  </dt>
                  <dd className="flex text-base text-primary-50">
                    <MailIcon
                      className="flex-shrink-0 w-6 h-6 text-primary-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">contact@geerd.ma</span>
                  </dd>
                  <dd className="flex text-base text-primary-50">
                    <LocationMarkerIcon
                      className="flex-shrink-0 w-6 h-6 text-primary-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">
                      350, Technopark Casablanca, Maroc
                    </span>
                  </dd>
                </dl>
                {/* <ul role="list" className="mt-8 flex space-x-12">
                  <li>
                    <a
                      className="text-primary-200 hover:text-primary-100"
                      href="#"
                    >
                      <span className="sr-only">Facebook</span>
                      <svg
                        className="w-7 h-7"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-primary-200 hover:text-primary-100"
                      href="#"
                    >
                      <span className="sr-only">GitHub</span>
                      <svg
                        className="w-7 h-7"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-primary-200 hover:text-primary-100"
                      href="#"
                    >
                      <span className="sr-only">Twitter</span>
                      <svg
                        className="w-7 h-7"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </li>
                </ul> */}
              </div>

              {/* Contact form */}
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                {/* <h3 className="text-lg font-medium text-stone-900">
                  Envoyez-nous un message
                </h3> */}
                <GoogleReCaptchaProvider reCaptchaKey="6Le_DSwjAAAAAMtlOinH4bxUHmmWdnLt0gWiDF6I">
                  <form
                    method="POST"
                    action="https://easytest.ma/submit"
                    id="contact-form"
                    className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                  >
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-stone-900"
                      >
                        Prénom
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          required
                          autoComplete="given-name"
                          className="py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-stone-900"
                      >
                        Nom
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last_name"
                          id="last_name"
                          required
                          autoComplete="family-name"
                          className="py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-stone-900"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          required
                          autoComplete="email"
                          className="py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-stone-900"
                        >
                          Téléphone
                        </label>
                        <span
                          id="phone-optional"
                          className="text-sm text-stone-500"
                        >
                          Facultatif
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          autoComplete="tel"
                          className="py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                          aria-describedby="phone-optional"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="jobtitle"
                        className="block text-sm font-medium text-stone-900"
                      >
                        Position
                      </label>
                      <select
                        id="jobtitle"
                        name="jobtitle"
                        className="mt-1 py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                      >
                        <option>CEO/President</option>
                        <option>Professeur/Enseignant</option>
                        <option>Gestionnaire d'école</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium text-stone-900"
                      >
                        Organisation
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="company"
                          id="company"
                          className="py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-stone-900"
                      >
                        Type
                      </label>
                      <select
                        id="org_type"
                        name="org_type"
                        className="mt-1 py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                      >
                        <option>Université</option>
                        <option>Entreprise</option>
                        <option>Agence Gouvernementale</option>
                        <option>Ecole</option>
                        <option>Indépendant</option>
                        <option>Autre</option>
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor="size"
                        className="block text-sm font-medium text-stone-900"
                      >
                        Size
                      </label>
                      <select
                        id="size"
                        name="size"
                        className="mt-1 py-3 px-4 block w-full shadow-sm text-stone-900 focus:ring-primary-500 focus:border-primary-500 border-stone-300 rounded-md"
                      >
                        <option>1-10</option>
                        <option>11-100</option>
                        <option>101-300</option>
                        <option>301-500</option>
                        <option>{">500"}</option>
                      </select>
                    </div>

                    <input
                      type="hidden"
                      id="origin"
                      name="origin"
                      value="compilatio"
                    />
                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                      <button
                        type="submit"
                        disabled={!showSaveButton}
                        className="disabled:opacity-50 mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto"
                      >
                        Envoyer
                      </button>
                    </div>
                    <GoogleReCaptcha
                      onVerify={() => {
                        setShowSaveButton(true)
                      }}
                    />
                  </form>
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
